<template>
  <div>
    <img
      v-if="media"
      :src="useFeaturedImageUrl(media.source_url, undefined, 400, 95)"
      :alt="media.alt_text"
      class="h-full w-full object-cover"
    />
  </div>
</template>

<script setup lang="ts">
import type { WP_REST_API_Attachment } from 'wp-types'

const props = defineProps<{
  id: number
}>()

const { public: { baseURL } } = useRuntimeConfig()

const { data: media } = useNuxtData<WP_REST_API_Attachment>(`featured-media-${props.id}`)

await useLazyFetch(`/wp/v2/media/${props.id}`, {
  baseURL,
  key: `featured-media-${props.id}`,
  query: {
    _fields: 'media_details,alt_text,source_url'
  },
  default() {
    return media.value
  },
  server: false
})
</script>